import React, { useEffect, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';

import { Card } from 'components';
import { Title, Text, Image } from 'elements';
import { SubTitle } from 'components/card/Card.styled';
import { ButtonText } from 'elements';
import { BlogPostsWrapper, FeaturedBlogPostWrapper } from './Blog.styled';

import { usePaging } from 'utils/hooks';
import paths from 'constants/paths';
import { useBreakpointAlt } from 'utils/hooks/useBreakpoint';

const categoryColorMap = {
  '3frw2PZk8rgsX4OLSOKuMl': 'accentColor',
  '3ininOVfCQUkfi92R5L5xm': 'secondaryAccentColor',
  '5qrjLKx4OYsy13fWD5LCx0': 'brandColor',
  '4F34MYgkN6l1LHaM2pYbAb': 'accentColor',
  '6Kopdrq9ToEPO4n81VWRuk': 'errorColor',
};

const BlogPosts = ({ category, customPageSize, customPosts, postContent }) => {
  const posts = customPosts.length ? customPosts : postContent;
  const size = useBreakpointAlt();
  const [pageSize, setPageSize] = useState(customPageSize || (size !== undefined && ['SM', 'MD'].includes(size) ? 6 : 12) || 6);
  const { pageCount, handleShowMore } = usePaging(pageSize, posts?.length || 0);

  useEffect(() => {
    const newPageSize = ['SM', 'MD'].includes(size) ? 6 : 12;
    if (newPageSize !== pageSize) setPageSize(newPageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  const featuredSort = (a, b) => {
    if (a.node.isFeatured && !b.node.isFeatured) return -1;
    if (!a.node.isFeatured && b.node.isFeatured) return 1;
    return 0;
  };

  const rankSort = (a, b) => {
    if (a.node.rank < b.node.rank) return -1;
    if (a.node.rank > b.node.rank) return 1;
    return 0;
  };

  const postDateSort = (a, b) => {
    const aDate = new Date(a.node.postDate);
    const bDate = new Date(b.node.postDate);
    if (aDate > bDate) return -1;
    if (bDate > aDate) return 1;
    return 0;
  };

  if (_isEmpty(posts)) return null;

  const sortedPosts = Array.from(posts).sort(postDateSort).sort(rankSort).sort(featuredSort);
  const featuredPost = !_isEmpty(sortedPosts) && sortedPosts?.find((post) => post.node.isFeatured);
  const pagedPosts = sortedPosts?.slice(featuredPost && size !== 'SM' ? 1 : 0, pageCount * pageSize);

  const getFeaturedListings = () => {
    const post = featuredPost;
    const firstCategory = get(post.node, 'postCategories[0]');
    const heroImage = get(post.node, 'heroImage.file.url');
    const postUrl = `${paths.BLOG}/${post.node.slug}`;
    return (
      <div>
        <div
          className='lg-blog__post--featured'
          style={{
            backgroundImage: `url(${heroImage})`,
          }}
          onClick={() => navigate(postUrl)}
        >
          <Card>
            <Link to={firstCategory.slug}>
              <SubTitle modifiers='brandColor'>{firstCategory.title}</SubTitle>
            </Link>
            <Title modifiers='tertiaryXSmall' content={post.node.title}></Title>
            <Text content={post.node.listingDescription} />
          </Card>
        </div>
      </div>
    );
  };

  const getListings = (posts) => {
    return posts.map((post) => {
      // if props.category is provided, we want to show that category title for post no matter how many categories post has assigned
      // if no category is provided we want to show the first assigned category for post
      const cat = !_isEmpty(category) ? category : get(post.node, 'postCategories[0]');
      return (
        <Card modifiers='fullBleed' key={post.node.id}>
          <Link to={`${paths.BLOG}/${post.node.slug}`}>
            <Image modifiers='cover' picture={get(post.node, 'listingImage.file.url')} alt={get(post.node, 'listingImage.description')} />
          </Link>
          <Link to={`${paths.BLOG}/${cat.slug}`}>
            <SubTitle modifiers={categoryColorMap[cat.sys.contentType.sys.id]}>{cat.title}</SubTitle>
          </Link>

          <Link to={`${paths.BLOG}/${post.node.slug}`}>
            <Title modifiers='tertiaryXSmall' content={post.node.title}></Title>
            <Text content={post.node.listingDescription}></Text>
          </Link>
        </Card>
      );
    });
  };

  return (
    <>
      <FeaturedBlogPostWrapper>{!customPosts.length && featuredPost && getFeaturedListings()}</FeaturedBlogPostWrapper>
      <BlogPostsWrapper>
        <div className='BlogPosts__inner'>{getListings(pagedPosts)}</div>
        {pagedPosts.length < sortedPosts.length && (
          <ButtonText modifiers={['secondary', 'underlined']} label='LOAD MORE' onClick={handleShowMore} />
        )}
      </BlogPostsWrapper>
    </>
  );
};

BlogPosts.defaultProps = {
  customPosts: [],
  category: {},
  customPageSize: null,
};

BlogPosts.propTypes = {
  customPosts: PropTypes.array,
  postContent: PropTypes.array,
  category: PropTypes.object,
  customPageSize: PropTypes.number,
};

export default BlogPosts;
